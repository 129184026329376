import React from "react"
import SEO from "../components/seo"
import { FaEnvelope } from 'react-icons/fa'

import "../scss/services.scss"

import Image1 from "../assets/img/youpalGroup/services/img1.png"
import Image2 from "../assets/img/youpalGroup/services/img2.png"
import Image3 from "../assets/img/youpalGroup/services/img3.png"
import Image4 from "../assets/img/youpalGroup/services/img4.png"
import Image5 from "../assets/img/youpalGroup/services/img5.png"
import appendScript from '../utils/appendScript';

const ServicesPage = () => {

  appendScript("https://ma.youpal.se/form/generate.js?id=30", "contact-us-mautic-internal-form");

  return (
    <div className="servicesMain">
      <SEO title="Services" keywords={[`youpal`, `services`]} />
      <section className="container">
        <h2>Services</h2>
        <p>
          With our management, we can make practice, art, science, and craft meet for your business. 
          <br />Youpal Group provides various solutions and services to multiple fields and businesses. We are ready to adapt to your needs and requirements, in order to make your workflow more efficient.
          <br />Even though we have a wide range of products and services for your businesses, we don’t limit you to this only. A team of professionals is evolving every day, in order to create your story and bring your business to a new level. 

          <br />Youpal does not only contributes to the improvement and technological progress of your business but impacts your culture, because we are proud to name Youpal a digital infrastructure culture enabler.  
        </p>
        <img className="contactUsHeaderImg" src={Image1} />
      </section>

      <section className="container aboutUsPageSection">
        <div className="row">
          <div className="col">
            <img className="sideImg" src={Image2} />
          </div>
          <div className="col sideContent text-left">
            <h2>PMO</h2>
            <p>
            PMO is responsible for maintaining the adopted project methodology. It enables tracking the progress and the status of the whole project. PMO sets up industry standards and implements project management best practices and strategies. 
            </p>
            <p>
            There are four core values for the PMO process. Those are the initiation, planning, execution, and of course closing. Our team is involved in setting up various types of PMO practices, ranging from the simple projects management office to very complex program management offices. 
            </p>
            <p>
            We make every process individual based on your requests and needs, type of business, and project management maturity. We always coordinate with our clients and together choose the most suitable setup. 
            </p>
            <p>
            Youpal has been working for the well-defined and time tested implementations and delivery methodologies that apply to a variety of sectors. With our experience, technologies, and customised approach, we enable the transformation of your business culture. 
            </p>
          </div>
        </div>
      </section>

      <section className="container aboutUsPageSection">
        <div className="row">
          <div className="col sideContent text-left">
            <h2>Management and Consulting</h2>
            <p>
            Youpal Management Consulting services focus on helping your organisation prioritize and execute strategic projects and achieve greater business efficiency and effectiveness in a short period of time. Our professional consultants work with your team to set up the most comprehensive and suitable business solution to your organisation and the challenges you are facing. 
            </p>
            <p>
            We practice helping your business improve performance and workflow. We can assist you in strategic planning, organisational development, facilitation, and choosing the right solution or product for your company. 
            </p>
            <p>
            Strategic planning and the business development process are two key factors in the success of your performance. We can assist you in creating the discipline committed to developing a roadmap to guide your company’s strategic initiatives. It shall directly and instantly impact efficient and market responsiveness. 
            </p>
          </div>
          <div className="col">
            <img className="sideImg" src={Image3} />
          </div>
        </div>
      </section>

      <section className="container aboutUsPageSection">
        <div className="row">
          <div className="col">
            <img className="sideImg" src={Image4} />
          </div>
          <div className="col sideContent text-left">
            <h2>Subject matter experts</h2>
            <p>
            You might need extra help especially when the tiring time of meeting new challenges comes. The professionals are cross-trained in their particular functions, and they are ready to tackle the situations that require even the most specialized knowledge. 
            </p>
            <p>
            We have experts in informational technologies, who can assist you in integrating the software applications, discovering bugs and anomalies during testing. Architects and engineers will assist you in building new technological approaches. 
            </p>
            <p>
            Project teams are ready to get engaged in the task and subject matter when their more generalized knowledge of a topic is insufficient for the problem in front of them. All innovators are striving to apply the new technologies or advancement to their business. 

            </p>
          </div>
        </div>
      </section>

      <section className="container aboutUsPageSection">
        <div className="row">
          <div className="col sideContent text-left">
            <h2>Design</h2>
            <p>
            We believe that “Design is intelligence made visible”. We understand the value of great design and define it as a science of art. A certain amount of misconceptions can always surround us, but we always try to make some things core. And the core thing is not about making it pretty or adding ornaments, but first and foremost it is about making the user’s interaction with the environment and surrounds more naturally. 
            </p>
            <p>
            We visualize your individualism and make your intelligence visible to your customers. Design is not art alone, but it is not a science either. It is about creating your vision and transferring your feelings. For us, your feeling and connection are the most important. We want to make you feel your business and products and your customers feel your nature. 
            </p>
          </div>
          <div className="col">
            <img className="sideImg" src={Image5} />
          </div>
        </div>
      </section>

      <section className="container aboutUsPageSection">
        <div className="row contactBox">
            <div className="col">
                <h2>Contact us</h2>
                <div className="sendLinkContainer">
                  <div className="formDiv">
                      <FaEnvelope size={25} />
                      {/* Mautic form */}
                      <div id='contact-us-mautic-internal-form'/>
                  </div>
                </div>
            </div>
        </div>
      </section>
    </div>
  )
}

export default ServicesPage
